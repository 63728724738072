// 约定一个通用的键名
const INFO_KEY = 'customer_info'
const STEP_KEY = 'customer_step'
const ADMIN_INFO_KEY = 'admin_info'

// 获取个人信息
export const getInfo = () => {
  const defaultObj = { Token: '', Id: 0, UserNo: '' }
  const result = localStorage.getItem(INFO_KEY)
  return result ? JSON.parse(result) : defaultObj
}

// 设置个人信息
export const setInfo = (obj) => {
  localStorage.setItem(INFO_KEY, JSON.stringify(obj))
}

// 移除个人信息
export const removeInfo = () => {
  localStorage.removeItem(INFO_KEY)
  localStorage.removeItem(STEP_KEY)
}

// 获取阶段
export const getStep = () => {
  const result = localStorage.getItem(STEP_KEY)
  return result || 0
}
// 设置阶段
export const setStep = (s) => {
  localStorage.setItem(STEP_KEY, s)
}

// 获取admin信息
export const getAdminInfo = () => {
  const defaultObj = { Token: '', Id: 0, UserNo: '' }
  const result = localStorage.getItem(ADMIN_INFO_KEY)
  return result ? JSON.parse(result) : defaultObj
}

// 设置admin信息
export const setAdminInfo = (obj) => {
  localStorage.setItem(ADMIN_INFO_KEY, JSON.stringify(obj))
}

// 移除admin信息
export const removeAdminInfo = () => {
  localStorage.removeItem(ADMIN_INFO_KEY)
}
