<template>
  <div>
    <!--          welcome-->
    <div class="row">
      <div class="col-md-12 grid-margin">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-end flex-wrap">
            <button type="button" class="btn btn-outline-danger btn-fw" style="position: absolute;top: 0;right: 15px" @click="handleQuit">退出</button>
            <div class="mr-md-3 mr-xl-5">
              <h2>{{ emplName }}，欢迎回来</h2>
              <p class="mb-md-0">这里展示着你的数据汇总</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'WelcomeBack',
  data () {
    return {
      emplName: ''
    }
  },
  created () {
    this.emplName = this.$store.state.admin.userInfo.EmplName
  },
  methods: {
    handleQuit () {
      this.$store.commit('admin/setUserInfo', {})
      this.$router.push('/home')
    }
  }
}
</script>

<style scoped lang="less">

</style>
