import request from '@/utils/request'
// admin login
export const login = (username, password) => {
  return request.post('/admin/administrator/login', {
    Username: username,
    Password: password
  })
}
// 列表
export const orderList = (query) => {
  return request.post('/admin/administrator/order-list', {
    Department: query.department,
    AgeBegin: query.ageBegin ? query.ageBegin : null,
    AgeEnd: query.ageEnd ? query.ageEnd : null,
    Gender: query.gender ? query.gender : null,
    CreateTimeBegin: query.createTimeBegin ? query.createTimeBegin : null,
    CreateTimeEnd: query.createTimeEnd ? query.createTimeEnd : null,
    Page: query.page,
    PageSize: query.pageSize
  })
}
// 获取订单详情
export const orderDetail = (orderNo) => {
  return request.post('/admin/administrator/info', {
    OrderNo: orderNo
  })
}

// 上传音频文件并检测
export const localDetect = (formData) => {
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' }
  }
  return request.post('/admin/backdoor/test/local-detect', formData, config)
}
