import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import OrderList from '@/components/admin/OrderList.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/views/layout/IndexView.vue'),
    redirect: '/home',
    children: [
      { path: '/home', component: () => import('@/views/layout/HomeView.vue') },
      { path: '/detect', component: () => import('@/views/layout/DetectView.vue') },
      {
        path: '/admin',
        component: () => import('@/views/layout/AdminView.vue'),
        redirect: '/admin/dashboard',
        children: [
          { path: 'dashboard', component: OrderList },
          { path: 'login', component: () => import('@/components/admin/LoginView.vue') }
          // // 动态路由传参，确认将来是哪order No
          // { path: 'order-detail/:orderno', component: () => import('@/components/admin/drawer/OrderDetail.vue') }
        ]
      },
      { path: '/test', component: () => import('@/views/layout/MyTestView.vue') }
    ]
  }
]

const router = new VueRouter({
  routes
})

// 全局路由守卫
router.beforeEach((to, from, next) => {
  const adminToken = store.getters.token
  if (adminToken) {
    next()
  } else {
    if (to.path.startsWith('/admin/login')) {
      next()
    } else if (to.path.startsWith('/admin')) {
      next('/admin/login')
    } else {
      next()
    }
  }
})

export default router
