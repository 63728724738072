<template>
  <div class="demo-drawer__content">
    <br>
    <br>
    <el-form :model="form" label-width="80px" label-position="left">
      <el-form-item label="所属部门" prop="department">
        <el-select v-model="form.department" placeholder="请选择所属部门" clearable>
          <el-option
              v-for="item in departmentOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="年龄范围" :rules="[{ type: 'number', message: '年龄必须为数字值'}]">
        <el-col :span="11">
          <el-input v-model.number="form.ageBegin" style="width: 80px"></el-input>
        </el-col>
        <el-col class="line" :span="2">-</el-col>
        <el-col :span="11">
          <el-input v-model.number="form.ageEnd" style="width: 80px"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="性别">
        <el-select v-model="form.gender" placeholder="性别" clearable>
          <el-option label="男" value="MALE"></el-option>
          <el-option label="女" value="FEMALE"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="demo-drawer__footer">
      <el-button @click="reset">重置</el-button>
      <el-button type="primary" @click="handleAdvancedQuery" style="margin-right: 20px">确定</el-button>
    </div>
  </div>
</template>
<script>
import { departmentOptions } from '@/utils/constants'

export default {
  name: 'AdvancedQuery',
  data () {
    return {
      departmentOptions: departmentOptions,
      form: {
        department: '',
        ageBegin: 0,
        ageEnd: 100,
        gender: ''
      }
    }
  },
  methods: {
    // 重置查询条件
    reset () {
      this.form = {
        department: '',
        ageBegin: 0,
        ageEnd: 100,
        gender: ''
      }
    },
    handleAdvancedQuery () {
      this.$emit('query', this.form)
      this.showDialog = false
    }
  }
}
</script>

<style scoped lang="less">
.demo-drawer__content {
  margin: 10px 10px 10px 30px;
  float: left;
  justify-content: center;
}
</style>
