<template>
  <div>
    <div style="font-size: 20px;">{{ question }}: {{ option }}</div>
    <div>
        <el-col :span="12">
          <div style="margin-top: 20px;" v-for="(item,index) in options" :key="index" :label="item.key">
            <span :class="{'active':item.key==option}">{{ item.key }}:{{ item.text }}【{{ item.value }}分】</span>
          </div>
        </el-col>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScaleDetail',
  props: {
    scaleData: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      question: '',
      options: [],
      option: ''
    }
  },
  created () {
    this.question = this.scaleData.question
    this.options = this.scaleData.options
    this.option = this.scaleData.option
  }
}
</script>

<style scoped lang="less">
.active {
  color: red;
}
</style>
