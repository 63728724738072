import { getAdminInfo, setAdminInfo } from '@/utils/storage'

export default {
  namespaced: true,
  state () {
    return {
      // admin权证相关
      userInfo: getAdminInfo()
    }
  },
  mutations: {
    // 所有mutations的第一个参数，都是state
    setUserInfo (state, obj) {
      state.userInfo = obj
      setAdminInfo(obj)
    }
  },
  actions: {
    logout (context) {
      // admin信息要重置
      context.commit('setUserInfo', {})
    }
  },
  getters: {}
}
