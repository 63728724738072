<template>
  <div>
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body dashboard-tabs p-0">
            <ul class="nav nav-tabs px-4" role="tablist">
              <li class="nav-item">
                <a class="nav-link active" id="overview-tab" data-toggle="tab" href="#overview" role="tab"
                   aria-controls="overview" aria-selected="true">Overview</a>
              </li>
            </ul>
            <div class="tab-content py-0 px-0">
              <div class="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview-tab">
                <div class="d-flex flex-wrap justify-content-xl-between">
                  <div
                      class="d-none d-xl-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                    <div class="d-flex flex-column justify-content-around">
                      <small class="mb-1 text-muted">ID</small>
                      <h5 class="mr-2 mb-0">{{ id }}</h5>
                    </div>
                  </div>
                  <div class="d-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                    <i class="mdi mr-3 icon-lg text-danger"></i>
                    <div class="d-flex flex-column justify-content-around">
                      <small class="mb-1 text-muted">订单号</small>
                      <h5 class="mr-2 mb-0">{{ orderNo }}</h5>
                    </div>
                  </div>
                  <div class="d-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                    <i class="mdi mdi-city mr-3 icon-lg text-black-50"></i>
                    <div class="d-flex flex-column justify-content-around">
                      <small class="mb-1 text-muted">部门</small>
                      <h5 class="mr-2 mb-0">{{ departmentName }}</h5>
                    </div>
                  </div>
                  <div class="d-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                    <i class="mdi mdi-blur mr-3 icon-lg text-primary"></i>
                    <div class="d-flex flex-column justify-content-around">
                      <small class="mb-1 text-muted">状态</small>
                      <div><label :class="statusClass(status)">{{ statusText }}</label></div>
                    </div>
                  </div>
                  <div class="d-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                    <i class="mdi mdi-account-card-details mr-3 icon-lg text-danger"></i>
                    <div class="d-flex flex-column justify-content-around">
                      <small class="mb-1 text-muted">MDD分数</small>
                      <div :class="scoreClass(score)">{{ score.toFixed(2) }}</div>
                    </div>
                  </div>
                  <div
                      class="d-flex py-3 border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                    <i class="mdi mdi-note-plus mr-3 icon-lg text-success"></i>
                    <div class="d-flex flex-column justify-content-around">
                      <small class="mb-1 text-muted">创建时间</small>
                      <h5 class="mr-2 mb-0">{{ createTime }}</h5>
                    </div>
                  </div>
                  <el-divider></el-divider>
                  <!--                    人的属性另起一行-->
                  <div class="d-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                    <i class="mdi mdi-account mr-3 icon-lg text-success"></i>
                    <div class="d-flex flex-column justify-content-around">
                      <small class="mb-1 text-muted">用户名</small>
                      <h5 class="mr-2 mb-0">{{ userNo }}</h5>
                    </div>
                  </div>
                  <div class="d-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                    <i class="mdi mdi-human-handsdown mr-3 icon-lg text-info"></i>
                    <div class="d-flex flex-column justify-content-around">
                      <small class="mb-1 text-muted">年龄</small>
                      <h5 class="mr-2 mb-0">{{ age }}岁</h5>
                    </div>
                  </div>
                  <div class="d-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                    <i class="mdi mdi-human-male-female mr-3 icon-lg text-warning"></i>
                    <div class="d-flex flex-column justify-content-around">
                      <small class="mb-1 text-muted">性别</small>
                      <h5 class="mr-2 mb-0">
                        <div :class="genderClass(gender)">{{ gender === 'MALE' ? '男' : '女' }}</div>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'OrderBaseDetail',
  props: {
    id: Number,
    orderNo: String,
    userNo: String,
    department: String,
    departmentName: String,
    age: Number,
    gender: String,
    status: String,
    statusText: String,
    score: Number,
    createTime: String
  },
  methods: {
    genderClass (gender) {
      return gender === 'MALE' ? 'text-success' : 'text-danger'
    },
    statusClass (status) {
      if (status === 'RECORDING') {
        return 'badge badge-warning'
      } else if (status === 'WAIT_ANALYZE') {
        return 'badge badge-info'
      } else if (status === 'ANALYZED_OK') {
        return 'badge badge-success'
      } else if (status === 'ANALYZED_FAIL') {
        return 'badge badge-danger'
      } else if (status === 'TIMEOUT') {
        return 'badge badge-danger'
      } else {
        return 'badge badge-danger'
      }
    },
    scoreClass (score) {
      return score < 0.6 ? 'text-success' : 'text-danger'
    }
  }
}
</script>

<style scoped lang="less">

</style>
