export const CREATE_ORDER = 0
export const WAV = 1
export const MSQ = 2
export const RESULT = 3

export const departmentOptions = [{
  label: '安定医院',
  value: 'adyy'
}, {
  label: '安定医院-儿科',
  value: 'adyy-ek'
}, {
  label: '北京理工大学',
  value: 'bit'
}, {
  label: '北京理工大学-通信学院',
  value: 'bit-ei'
}, {
  label: '北京理工大学-计算机学院',
  value: 'bit-cs'
}]
