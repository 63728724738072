import store from '@/store'
import axios from 'axios'
import router from '@/router'

// 创建 axios 实例，将来对创建出来的实例，进行自定义配置
// 好处：不会污染原始的 axios 实例
const instance = axios.create({
  baseURL: 'http://123.57.247.16:8888',
  timeout: 60000
})

// 自定义配置 - 请求/响应 拦截器
// 添加请求拦截器
instance.interceptors.request.use(function (config) {
  // 只要有token，就在请求时携带，便于请求需要授权的接口
  const authentication = store.getters.authentication
  if (authentication) {
    config.headers.authentication = authentication
    config.headers.platform = 'H5'
  }

  const token = store.getters.token
  if (token) {
    config.headers.token = token
    config.headers.platform = 'H5'
  }

  config.headers['Access-Control-Allow-Origin'] = '*'

  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
  // 2xx 范围内的状态码都会触发该函数。
  // 对响应数据做点什么 (默认axios会多包装一层data，需要响应拦截器中处理一下)
  const res = response.data
  // if (res.Ret === undefined) {
  //   // 抛出一个错误的promise
  //   // eslint-disable-next-line prefer-promise-reject-errors
  //   return Promise.reject('服务器错误')
  // }
  return res
}, function (error) {
  // 超出 2xx 范围的状态码都会触发该函数。
  // 对响应错误做点什么
  console.log(error)
  console.log(error.response.status)
  if (error.response.status === 401) {
    // Unauthorized，跳转到admin login
    alert('登录失效，重新登陆')
    if (error.response.config.url.startsWith('/customer')) {
      store.commit('user/setUserInfo', {})
      store.commit('user/setStep', 0)
    } else {
      store.commit('admin/setUserInfo', {})
      router.push('/admin/login')
    }
    return new Promise(function () {}) // 空的promise对象，
  }
  return Promise.reject(error)
})

// 导出配置好的实例
export default instance
