import { getInfo, setInfo, getStep, setStep } from '@/utils/storage'

export default {
  namespaced: true,
  state () {
    return {
      // 个人权证相关
      userInfo: getInfo(),
      // 阶段
      step: getStep()
    }
  },
  mutations: {
    // 所有mutations的第一个参数，都是state
    setUserInfo (state, obj) {
      state.userInfo = obj
      setInfo(obj)
    },
    setStep (state, s) {
      state.step = s
      setStep(s)
    }
  },
  actions: {
    logout (context) {
      // 个人信息要重置
      context.commit('setUserInfo', {})
      context.commit('setStep', 0)
    }
  },
  getters: {}
}
