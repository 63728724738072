<template>
  <div>
    <OrderBaseDetail
        :id="this.id"
        :orderNo="this.orderNo"
        :userNo="this.userNo"
        :department="this.department"
        :departmentName="this.departmentName"
        :age="this.age"
        :gender="this.gender"
        :status="this.status"
        :statusText="this.statusText"
        :score="this.score"
        :createTime="this.createTime"
    ></OrderBaseDetail>

<!--    量表列表-->
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <el-col>量表列表</el-col>
              <table class="table table-hover">
                <thead>
                <tr>
                  <th>题目信息</th>
                  <th style="width: 200px">创建时间</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item,index) in this.scaleInfoList" :key="index">
                  <td>
<!--                    <json-viewer-->
<!--                        :value="JSON.parse(item.ScaleData)"-->
<!--                        :expand-depth="5"-->
<!--                        sort-->
<!--                    ></json-viewer>-->
                    <ScaleDetail :scaleData="JSON.parse(item.ScaleData)"></ScaleDetail>
                  </td>
                  <td>{{ item.CreateTime }}</td>
                </tr>
                </tbody>
              </table>
              <el-col style="margin-top: 50px;font-size: 20px">量表结论：</el-col>
              <div>{{ this.scaleSuggestion }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

<!--    wav table-->
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <el-col>音频列表</el-col>
              <table class="table table-hover">
                <thead>
                <tr>
                  <th>状态</th>
                  <th>类型</th>
                  <th>分数</th>
                  <th>地址</th>
                  <th>创建时间</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item,index) in this.wavInfoList" :key="index">
                  <td>{{ item.StatusText }}</td>
                  <td>{{ item.WavTypeText }}</td>
                  <td>{{ item.Score }}</td>
                  <td>{{ item.WavPath }}</td>
                  <td>{{ item.CreateTime }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { orderDetail } from '@/api/admin'
import OrderBaseDetail from '@/components/admin/drawer/OrderBaseDetail.vue'
import ScaleDetail from '@/components/admin/drawer/ScaleDetail.vue'

export default {
  name: 'OrderDetail',
  components: {
    OrderBaseDetail,
    ScaleDetail
  },
  props: {
    orderNo: String
  },
  data () {
    return {
      id: 0,
      userNo: '',
      department: '',
      departmentName: '',
      age: 0,
      gender: '',
      status: '',
      statusText: '',
      score: 0,
      createTime: '',
      wavInfoList: [],
      scaleInfoList: [],
      scaleSuggestion: ''
    }
  },
  computed: {},
  async created () {
    // 获取order详情
    const { Data: data } = await orderDetail(this.orderNo)
    if (data) {
      this.id = data.Id
      this.userNo = data.UserNo
      this.department = data.Department
      this.departmentName = data.DepartmentName
      this.age = data.Age
      this.gender = data.Gender
      this.status = data.Status
      this.statusText = data.StatusText
      this.score = data.Score
      this.createTime = data.CreateTime
      this.wavInfoList = data.WavInfoList
      this.scaleInfoList = data.ScaleInfoList
      this.scaleSuggestion = data.ScaleSuggestion
      // console.log(this.scaleSuggestion)
    }
  },
  methods: {}

}
</script>

<style scoped lang="less">

</style>
