import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import admin from './modules/admin'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
    // customer
    authentication (state) {
      if (state.user.userInfo === null) {
        return ''
      }
      return state.user.userInfo.Token
    },
    orderId (state) {
      if (state.user.userInfo === null) {
        return 0
      }
      return state.user.userInfo.Id
    },
    step (state) {
      return state.user.step
    },
    // admin
    token (state) {
      if (state.admin.userInfo === null) {
        return ''
      }
      return state.admin.userInfo.Token
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user,
    admin
  }
})
