<template>
<div>
  <WelcomeBack></WelcomeBack>
  <!--  检索条件的抽屉-->
  <div>
    <el-drawer :visible.sync="showDialog" :with-header="false" direction="rtl">
      <AdvancedQuery @query="handleQuery"></AdvancedQuery>
    </el-drawer>
  </div>
  <!--            table-->
  <div class="row">
    <div class="col-lg-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <el-row>
            <el-col :span="6"><h4 class="card-title">检测记录</h4></el-col>
            <el-col :span="6" :offset="12">
              <el-button style="margin-right: 30px;float: right" type="primary" plain @click="showDialog=true">查询</el-button></el-col>
          </el-row>
          <div class="table-responsive">
            <table class="table table-hover">
              <thead>
              <tr>
                <th>ID</th>
                <th>订单编号</th>
                <th>用户编号</th>
                <th>所属部门</th>
                <th>年龄</th>
                <th>性别</th>
                <th>状态</th>
                <th>MDD评分</th>
                <th>创建时间</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item,index) in items" :key="index" @click="handleClickOrder(item)">
                <td>{{ item.Id }}</td>
                <td>{{ item.OrderNo }}</td>
                <td>{{ item.UserNo }}</td>
                <td>{{ item.DepartmentName }}</td>
                <td>{{ item.Age }}岁</td>
                <td :class="genderClass(item)">{{ item.Gender==='MALE'? '男':'女' }}</td>
                <td><label :class="statusClass(item)">{{ item.Status }}</label></td>
                <td :class="scoreClass(item)">{{ item.Score.toFixed(2) }}</td>
                <td>{{ item.CreateTime }}</td>
              </tr>
              </tbody>
            </table>
              <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="form.page"
                  :page-sizes="[10, 50, 100]"
                  :page-size="form.pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="totalCount">
              </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--  详情的抽屉-->
  <div>
    <MyDrawer
        title=" "
        :visible.sync='orderNo'
        :headerShow="true"
        width="100%"
        main-background="#EBEEF5"
    >
      <OrderDetail :orderNo="this.orderNo"></OrderDetail>
    </MyDrawer>
  </div>
</div>
</template>
<script>
import { orderList } from '@/api/admin'
import WelcomeBack from '@/components/admin/WelcomeBack.vue'
import { departmentOptions } from '@/utils/constants'
import AdvancedQuery from '@/components/admin/drawer/AdvancedQuery.vue'
import MyDrawer from '@/components/admin/drawer/MyDrawer.vue'
import OrderDetail from '@/components/admin/drawer/OrderDetail.vue'

export default {
  name: 'OrderList',
  components: { OrderDetail, MyDrawer, AdvancedQuery, WelcomeBack },
  data () {
    return {
      departmentOptions: departmentOptions,
      showDialog: false,
      orderNo: '',
      form: {
        department: '',
        ageBegin: 0,
        ageEnd: 100,
        gender: '',
        createTimeBegin: '',
        createTimeEnd: '',
        page: 1,
        pageSize: 10
      },
      items: [],
      totalCount: 0
    }
  },
  async created () {
    this.getData()
  },
  methods: {
    async getData () {
      // console.log(this.form)
      const { Data: data } = await orderList(this.form)
      // console.log(data)
      if (data) {
        this.items = data.Items
        this.totalCount = data.TotalCount
      } else {
        this.items = []
        this.totalCount = 0
      }
    },
    genderClass (item) {
      return item.Gender === 'MALE' ? 'text-success' : 'text-danger'
    },
    statusClass (item) {
      if (item.Status === 'RECORDING') {
        return 'badge badge-warning'
      } else if (item.Status === 'WAIT_ANALYZE') {
        return 'badge badge-info'
      } else if (item.Status === 'ANALYZED_OK') {
        return 'badge badge-success'
      } else if (item.Status === 'ANALYZED_FAIL') {
        return 'badge badge-danger'
      } else if (item.Status === 'TIMEOUT') {
        return 'badge badge-danger'
      } else {
        return 'badge badge-danger'
      }
    },
    scoreClass (item) {
      return item.Score < 0.6 ? 'text-success' : 'text-danger'
    },
    // 翻页
    handleSizeChange (val) {
      this.form.pageSize = val
      this.getData()
    },
    handleCurrentChange (val) {
      this.form.page = val
      this.getData()
    },
    handleQuery (sonForm) {
      // console.log(sonForm)
      // 从son form 复制参数给自己
      this.form.department = sonForm.department
      this.form.ageBegin = sonForm.ageBegin
      this.form.ageEnd = sonForm.ageEnd
      this.form.gender = sonForm.gender
      this.getData()
      this.showDialog = false
    },
    // 点击了列表的一行
    handleClickOrder (item) {
      this.orderNo = item.OrderNo
    }
  }
}
</script>

<style scoped lang="less">
.orderDetail{
  position: fixed;
  top: 0;
  right: 0;
}
</style>
